// Composant version 2021
// ----------------------------------

import React,{useEffect,useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

import { ResponsiveContainer, BarChart, PieChart, ComposedChart , Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Pie, Cell, Line } from 'recharts'; // https://recharts.org/en-US/api/ResponsiveContainer

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import {I_Pump}          from '../services/I_Pump';
import {I_Drink}         from '../services/I_Drink';
import {I_Stock}         from '../services/I_Stock';
import S_Stocks           from '../services/S_Stocks';
import PumpsAccordions   from './PumpsAccordions';
import WherePie          from './WherePie';

// Styles
// import './PanelHome.scss';

type PanelHomeProps = {
  pumps: I_Pump[];
  addPump: any;
  editPump: any;
  drinks: I_Drink[];
  addDrink: any;
  editDrink: any;
  stocks: I_Stock[];
  addStock: any;
  editStock: any;
  selectType: any;
}

const COLORS = ['#8c5bff', '#5ba5ff', '#5bf1ff','#5bf1ff', '#6d6d6d'];

function PanelHome(props:PanelHomeProps) {

  // props
  let {pumps,addPump,editPump,drinks,addDrink,editDrink,stocks,addStock,editStock,selectType} = props;

  // state


  // chargement des donnees
  useEffect(() => {

  }, []);

  // autres variables

  // pumps
  let data1 = [];
  let pumpsByDate = S_Date.groupByDate(pumps);
  let datesInMillis = Object.keys(pumpsByDate).reverse();
  // console.log(datesInMillis);
  let firstDate = parseInt(datesInMillis[0]);
  let lastDate = parseInt(datesInMillis[datesInMillis.length-1]);
  // console.log(firstDate,lastDate);
  let dateInMillis = firstDate;
  while(dateInMillis <= lastDate){
    let dateInString = S_Date.formatDateInStringWithNameNoYear(dateInMillis);
    let pumpsDay = pumpsByDate[dateInMillis];
    let totalDay = 0;
    if(!!pumpsDay){
      for(var j = 0; j < pumpsDay.length; j++){
        let ml = pumpsDay[j].ml;
        // console.log(ml);
        totalDay += ml;
      }
    }
    data1.push({
      "date": dateInString,
      "ml": totalDay
    });
    dateInMillis = dateInMillis + 1000*60*60*24;
  }

  // drinks
  let data3 = [];
  let drinksByDate = S_Date.groupByDate(drinks);
  let datesInMillis3 = Object.keys(drinksByDate).reverse();
  // console.log(datesInMillis);
  let firstDate3 = parseInt(datesInMillis3[0]);
  let lastDate3 = parseInt(datesInMillis3[datesInMillis3.length-1]);
  // console.log(firstDate,lastDate);
  let dateInMillis3 = firstDate3;
  while(dateInMillis3 <= lastDate3){
    let dateInString = S_Date.formatDateInStringWithNameNoYear(dateInMillis3);
    let drinksDay = drinksByDate[dateInMillis3];
    let totalDay = 0;
    if(!!drinksDay){
      for(var j = 0; j < drinksDay.length; j++){
        let ml = drinksDay[j].ml;
        // console.log(ml);
        totalDay += ml;
      }
    }
    data3.push({
      "date": dateInString,
      "ml": totalDay
    });
    dateInMillis3 = dateInMillis3 + 1000*60*60*24;
  }

  // pumps - drinks
  let data4 = [];
  let firstDate4 = Math.min(firstDate,firstDate3);
  let lastDate4 = Math.max(lastDate,lastDate3);
  let dateInMillis4 = firstDate4;
  while(dateInMillis4 <= lastDate4){
    let dateInString = S_Date.formatDateInStringWithNameNoYear(dateInMillis4);
    let pumpsDay = pumpsByDate[dateInMillis4];
    let totalPumpsDay = 0;
    if(!!pumpsDay){
      for(var j = 0; j < pumpsDay.length; j++){
        let ml = pumpsDay[j].ml;
        // console.log(ml);
        totalPumpsDay += ml;
      }
    }
    let drinksDay = drinksByDate[dateInMillis4];
    let totalDrinksDay = 0;
    if(!!drinksDay){
      for(var j = 0; j < drinksDay.length; j++){
        let ml = drinksDay[j].ml;
        // console.log(ml);
        totalDrinksDay += ml;
      }
    }
    let diffDay = totalPumpsDay - totalDrinksDay;
    data4.push({
      "date": dateInString,
      "ml": diffDay
    });
    dateInMillis4 = dateInMillis4 + 1000*60*60*24;
  }

  // stocks
  let data2 = [];
  let stocksByDate = S_Date.groupByDate(stocks);
  let datesInMillis2 = Object.keys(stocksByDate).reverse();
  let firstDate2 = parseInt(datesInMillis2[0]);
  let lastDate2 = parseInt(datesInMillis2[datesInMillis2.length-1]);
  let dateInMillis2 = firstDate2;
  let lastTotal = 0;
  let currentStockAtDay = 0;
  let currentUsedAtDay = 0;
  while(dateInMillis2 <= lastDate2){
    let dateInString = S_Date.formatDateInStringWithNameNoYear(dateInMillis2);
    let stocksDay = stocksByDate[dateInMillis2];
    let totalDay = 0;
    if(!!stocksDay){
      for(var j = 0; j < stocksDay.length; j++){
        let ml = stocksDay[j].ml;
        // console.log(ml);
        totalDay += ml;
      }
    }
    lastTotal += totalDay;
    currentUsedAtDay = S_Stocks.getMlUsedBefore(dateInMillis2);
    let usedAtDay = S_Stocks.getMlUsedAtDay(dateInMillis2);
    currentStockAtDay = lastTotal - currentUsedAtDay;
    // console.log('+',totalDay,'=',lastTotal,'-',currentUsedAtDay);
    data2.push({
      "date": dateInString,
      "Restant": currentStockAtDay,
      "Total": lastTotal,
      "Total utilisé": currentUsedAtDay,
      "Utilisé": usedAtDay
    });
    dateInMillis2 = dateInMillis2 + 1000*60*60*24;
  }
  console.log(data4);


  return (
    <Container fixed className="panel-contents pb-6">

      <div className="mt-vague">

        {data1.length>0 ? (
        <div className="mv-2 mt-3">
          <h3 className="text-center">Pompage (ml par jour)</h3>
          <ResponsiveContainer height={200}>
            <BarChart data={data1}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis unit="ml"/>
              <Tooltip />
              <Bar dataKey="ml" fill="#0099ff" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        ):null}

        {data3.length>0 ? (
        <div className="mv-2">
          <h3 className="text-center">Consommation (ml par jour)</h3>
          <ResponsiveContainer height={200}>
            <BarChart data={data3}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis unit="ml"/>
              <Tooltip />
              <Bar dataKey="ml" fill="#0099ff" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        ):null}

        {data1.length>0 && data3.length>0 ? (
        <div className="mv-2">
          <h3 className="text-center">Pompage - Consommation (ml par jour)</h3>
          <ResponsiveContainer height={200}>
            <BarChart data={data4}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis unit="ml"/>
              <Tooltip />
              <Bar dataKey="ml" fill="#0099ff" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        ):null}

        {data2.length>0 ? (
        <div className="mv-2">
          <h3 className="text-center">Stocks (ml)</h3>
          <ResponsiveContainer height={200}>
            <ComposedChart data={data2}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis unit="ml"/>
              <Tooltip />
              <Area type="monotone" dataKey="Total" fill="#8884d8" stroke="#8884d8" />
              <Bar dataKey="Restant" fill="#0099ff" />
              <Bar dataKey="Utilisé" fill="#ff7300" />
              <Legend />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        ):null}

        {stocks.length>0 ? (
        <div className="mv-2">
          <h3 className="text-center">Stocks par lieu</h3>
          <WherePie stocks={stocks} />
        </div>
        ):null}
        
      </div>
      
      <div className="panel-contents-footer text-right">
        <Fab aria-label="Ajouter" onClick={selectType} color={'primary'} className=""><AddIcon /></Fab>
      </div>
    </Container>
  );
}

export default PanelHome;