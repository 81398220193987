import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import S_Pumps         from '../services/S_Pumps';
import {I_Pump}        from '../services/I_Pump';

// Styles
// import './DialogEditPump.scss';

type DialogEditPumpProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickDelete: any;
  onClickValidate: any;
  item: I_Pump;
}

function DialogEditPump(props:DialogEditPumpProps) {

  // props
  let {isDialogOpened,closeDialog,onClickDelete,onClickValidate,item} = props;

  console.log('item',item);

  let dayMillis = item ? item.date : undefined;

  // state
  const [dateForMaterial, setDateForMaterial] = useState(S_Date.formatDateForMaterial(dayMillis));
  const [ml, setMl] = useState(item ? item.ml : 0);
  const [min, setMin] = useState(item ? item.min : undefined);
  const [mode, setMode] = useState(item ? item.mode : undefined);

  function changeDate(value){
    setDateForMaterial(value);
  }
  function changeMl(value){
    setMl(value);
  }
  function changeMin(value){
    setMin(value);
  }
  function changeMode(value){
    setMode(value);
  }

  // on transforme la date "material" en date in millis, et on la cale à 1h.
  let daySelectedInMillis = S_Date.getDayReference(S_Date.formatDateInMillis(dateForMaterial));

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Modifier un pompage</DialogTitle>
      <DialogContent>

        <TextField label="Date" type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth 
          value={S_Date.formatDateForMaterial(dateForMaterial)} 
          onChange={(e) => changeDate(e.target.value)} />
        <TextField id="quantity" label={'Quantité pompée (ml)'} type="number" InputProps={{inputProps: { min: 0 }}} fullWidth size="small"
          value={ml}
          onChange={(e) => changeMl(e.target.value)} />
        <TextField id="time" label={'En ?? minutes'} type="number" InputProps={{inputProps: { min: 0 }}} fullWidth size="small"
          value={min}
          onChange={(e) => changeMin(e.target.value)} />
        <TextField id="mode" label={'Mémo (Manuel / Electrique...)'} type="text" fullWidth size="small"
          value={mode}
          onChange={(e) => changeMode(e.target.value)} />

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Annuler</Button>
        <Button onClick={(e) => onClickDelete()} color="primary">Supprimer</Button>
        <Button onClick={(e) => onClickValidate(daySelectedInMillis,ml,min,mode)} color="primary">Valider</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogEditPump;
