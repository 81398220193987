import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import StorageIcon from '@material-ui/icons/Storage';

// Composants génériques
import S_App      from '../../services/S_App';

// Composants propres

// Styles
// import './DialogSelectType.scss';

type DialogSelectTypeProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickValidate: any;
}

function DialogSelectType(props:DialogSelectTypeProps) {

  // props
  let {isDialogOpened,closeDialog,onClickValidate} = props;

  // state

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogContent className="text-center">

        <h3>Ajouter une donnée</h3>

        <Fab onClick={(e) => onClickValidate('pump')} color="primary" variant="extended" className="mh-05 mv-05">
          <AddIcon className="mr-05"/>
          <span className="fab-label">Pompage</span>
        </Fab>
        <Fab onClick={(e) => onClickValidate('drink')} color="primary" variant="extended" className="mh-05 mv-05">
          <RemoveIcon className="mr-05"/>
          <span className="fab-label">Consommation</span>
        </Fab>
        <Fab onClick={(e) => onClickValidate('stock')} color="primary" variant="extended" className="mh-05 mv-05">
          <StorageIcon className="mr-05"/>
          <span className="fab-label">Stock</span>
        </Fab>

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Annuler</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogSelectType;
