import S_App    from   '../../services/S_App';
import {I_Drink} from   './I_Drink';

let TEST_DRINKS = [];

let S_Drinks = {
  STORAGE_KEY_DRINKS : 'drinks',

  drinks : [],

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Drinks.tsx -- fetch()...');
    var drinks = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Drinks.STORAGE_KEY_DRINKS];
    if(drinks){
      S_Drinks.drinks = JSON.parse(drinks);
    }else{
      S_Drinks.drinks = TEST_DRINKS;
    }

    for(var i = 0; i < S_Drinks.drinks.length; i++){
      S_Drinks.drinks[i].ml = parseInt(S_Drinks.drinks[i].ml+'');
    }
    S_Drinks.store(() => {});
      
    if(S_App.isLogDebug) console.log('-- S_Drinks.tsx -- fetch() drinks',S_Drinks.drinks);
    if(cb)
      cb();
  },
  store: (cb) => {
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Drinks.STORAGE_KEY_DRINKS] = JSON.stringify(S_Drinks.drinks);
    if(S_App.isLogDebug) console.log('-- S_Drinks.tsx -- store() drinks',S_Drinks.drinks); 
    if(cb)
      cb();
  },


  getById: (id:number|string) => {
    return S_App.getByID(S_Drinks.drinks,id);
  },

  add(dateInMillis,ml,min,mode,cb){
    if(S_App.isLogDebug) console.log('-- S_Drinks.tsx -- add()...');
      
    let dateNow = (new Date()).getTime();
    let newOne = {
      id: dateNow,
      date: dateInMillis,
      ml: parseInt(ml+''),
      min: min,
      mode: mode
    };
    if(!S_Drinks.drinks)
      S_Drinks.drinks = [];
    S_Drinks.drinks.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Drinks.tsx -- add() added',newOne);
    S_Drinks.store(() => {
      if(cb) cb();
    });
  },
  update(value,field,drink:I_Drink,cb){
    if(!S_Drinks.drinks || S_Drinks.drinks.length===0 || !drink){
      if(cb) cb();
      return;
    }
      
    let foundData = undefined;
    for(var i = 0; i < S_Drinks.drinks.length; i++){
      if(S_Drinks.drinks[i].id === drink.id){
        foundData = S_Drinks.drinks[i];
        if('ml'===field || 'min'===field){
          foundData[field] = parseInt(value+'');
        }else if('date'===field){
          // value doit etre en millis
          foundData[field] = value!==undefined ? (new Date(value)).getTime() : undefined;
        }else{
          foundData[field] = value;
        }
        break;
      }
    }
    console.log('Update',field,'as',value,'...',foundData);
    S_Drinks.store(() => {
      if(cb) cb(foundData);
    });
  },
  remove(drink:I_Drink,cb){
    if(!S_Drinks.drinks || S_Drinks.drinks.length===0 || !drink){
      if(cb) cb();
      return;
    }
    if(S_App.isLogDebug) console.log('-- S_Drinks.tsx -- remove() delete',drink,'...'); 
    let foundNoteIndex = S_App.getIndexOfByID(S_Drinks.drinks,drink.id);
    if(foundNoteIndex !== -1){
      S_Drinks.drinks.splice(foundNoteIndex,1);
      if(S_App.isLogDebug) console.log('-- S_Drinks.tsx -- remove() deleted'); 
    }else{
      if(S_App.isLogDebug) console.warn('-- S_Drinks.tsx -- remove() not found');
    }
    S_Drinks.store(() => {
      if(cb) cb();
    });
  }

};
export default S_Drinks;