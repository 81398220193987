// Composant version 2021
// ----------------------------------

import React,{useEffect,useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

// Composants génériques
import S_App      from '../../services/S_App';

// Composants propres
import {I_Drink}         from '../services/I_Drink';
import DrinksAccordions    from './DrinksAccordions';

// Styles
// import './PanelDrinks.scss';

type PanelDrinksProps = {
  drinks: I_Drink[];
  addDrink: any;
  editDrink: any;
}

function PanelDrinks(props:PanelDrinksProps) {

  // props
  let {drinks,addDrink,editDrink} = props;

  // state


  // chargement des donnees
  useEffect(() => {

  }, []);

  // autres variables

  return (
    <Container fixed className="panel-contents pb-6">

      <div className="mt-vague">
        <DrinksAccordions Drinks={drinks} editDrink={editDrink}/>
      </div>
      
      <div className="panel-contents-footer text-right">
        <Fab aria-label="Ajouter" onClick={addDrink} className=""><AddIcon /></Fab>
      </div>
    </Container>
  );
}

export default PanelDrinks;
