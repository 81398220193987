import React, { useState, useEffect } from 'react';

// Composants Material UI
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import StorageIcon from '@material-ui/icons/Storage';
import BarChartIcon from '@material-ui/icons/BarChart';

// Composants generiques
import S_App      from './services/S_App';
// import Panels       from './components/Panels';
import { Panels } from 'react-mui-pwa-tools';

// Composants propres
import S_Pumps          from './metier/services/S_Pumps';
import {I_Pump}         from './metier/services/I_Pump';
import S_Stocks         from './metier/services/S_Stocks';
import {I_Stock}        from './metier/services/I_Stock';
import S_Drinks         from './metier/services/S_Drinks';
import {I_Drink}        from './metier/services/I_Drink';
import PanelConfig      from './metier/components/PanelConfig';
import PanelHome        from './metier/components/PanelHome';
import PanelPumps       from './metier/components/PanelPumps';
import PanelDrinks      from './metier/components/PanelDrinks';
import PanelStocks      from './metier/components/PanelStocks';
import DialogSelectType from './metier/dialogs/DialogSelectType';
import DialogAddPump    from './metier/dialogs/DialogAddPump';
import DialogEditPump   from './metier/dialogs/DialogEditPump';
import DialogAddStock   from './metier/dialogs/DialogAddStock';
import DialogEditStock  from './metier/dialogs/DialogEditStock';
import DialogAddDrink   from './metier/dialogs/DialogAddDrink';
import DialogEditDrink  from './metier/dialogs/DialogEditDrink';

// Styles
import './App.scss';

function App() {

  // state
  const [swipePanelAllowed, setSwipePanelAllowed] = useState(true);
  const [isDialogSelectTypeOpened, setIsDialogSelectTypeOpened] = useState(false);
  const [pumps, setPumps] = useState([]);
  const [isDialogAddPumpOpened, setIsDialogAddPumpOpened] = useState(false);
  const [isDialogEditPumpOpened, setIsDialogEditPumpOpened] = useState(false);
  const [pumpToEdit, setPumpToEdit] = useState(undefined);
  const [stocks, setStocks] = useState([]);
  const [isDialogAddStockOpened, setIsDialogAddStockOpened] = useState(false);
  const [isDialogEditStockOpened, setIsDialogEditStockOpened] = useState(false);
  const [stockToEdit, setStockToEdit] = useState(undefined);
  const [drinks, setDrinks] = useState([]);
  const [isDialogAddDrinkOpened, setIsDialogAddDrinkOpened] = useState(false);
  const [isDialogEditDrinkOpened, setIsDialogEditDrinkOpened] = useState(false);
  const [drinkToEdit, setDrinkToEdit] = useState(undefined);


  // chargement des donnees
  useEffect(() => {
    S_Pumps.fetch(() => {
      setPumps([...S_Pumps.pumps]);
    });
    S_Stocks.fetch(() => {
      setStocks([...S_Stocks.stocks]);
    });
    S_Drinks.fetch(() => {
      setDrinks([...S_Drinks.drinks]);
    });
  }, []);


  // ----------------------------

  function selectType(){
    setIsDialogSelectTypeOpened(true);
  }
  function selectTypeValidate(type){
    if(type==='pump'){
      setIsDialogSelectTypeOpened(false);
      addPump();
    }
    if(type==='stock'){
      setIsDialogSelectTypeOpened(false);
      addStock();
    }
    if(type==='drink'){
      setIsDialogSelectTypeOpened(false);
      addDrink();
    }
  }


  // ----------------------------

  function addPump(){
    setIsDialogAddPumpOpened(true);
  }
  function addPumpValidate(dateInMillis,ml,min,mode){
    S_Pumps.add(dateInMillis,ml,min,mode,() => {
      setPumps([...S_Pumps.pumps]);
      setIsDialogAddPumpOpened(false);
    });
  }
  function editPump(pump:I_Pump){
    setPumpToEdit(pump);
    setIsDialogEditPumpOpened(true);
  }
  function editPumpValidate(dateInMillis,ml,min,mode){
    S_Pumps.update(dateInMillis,'date',pumpToEdit,() => {
      S_Pumps.update(ml,'ml',pumpToEdit,() => {
        S_Pumps.update(min,'min',pumpToEdit,() => {
          S_Pumps.update(mode,'mode',pumpToEdit,() => {
            setPumps([...S_Pumps.pumps]);
            setIsDialogEditPumpOpened(false);
            setPumpToEdit(undefined);
          });
        });
      });
    });
  }
  function editPumpDelete(){
    S_Pumps.remove(pumpToEdit,() => {
      setPumps([...S_Pumps.pumps]);
      setIsDialogEditPumpOpened(false);
      setPumpToEdit(undefined);
    });
  }
  function closeEditPump(){
    setIsDialogEditPumpOpened(false);
    setPumpToEdit(undefined);
  }


  // ----------------------------

  function addStock(){
    setIsDialogAddStockOpened(true);
  }
  function addStockValidate(dateInMillis_pump,dateInMillis,ml,where,category1){
    S_Stocks.add(dateInMillis_pump,dateInMillis,ml,where,category1,() => {
      setStocks([...S_Stocks.stocks]);
      setIsDialogAddStockOpened(false);
    });
  }
  function editStock(stock:I_Stock){
    setStockToEdit(stock);
    setIsDialogEditStockOpened(true);
  }
  function editStockValidate(dateInMillis_pump,dateInMillis,ml,where,used,dateUsedInMillis,category1){
    S_Stocks.update(dateInMillis_pump,'datePump',stockToEdit,() => {
      S_Stocks.update(dateInMillis,'date',stockToEdit,() => {
        S_Stocks.update(ml,'ml',stockToEdit,() => {
          S_Stocks.update(where,'where',stockToEdit,() => {
            S_Stocks.update(used,'used',stockToEdit,() => {
              S_Stocks.update(dateUsedInMillis,'dateUsed',stockToEdit,() => {
                S_Stocks.update(category1,'category1',stockToEdit,() => {
                  setStocks([...S_Stocks.stocks]);
                  setIsDialogEditStockOpened(false);
                  setStockToEdit(undefined);
                });
              });
            });
          });
        });
      });
    });
  }
  function editStockDelete(){
    S_Stocks.remove(stockToEdit,() => {
      setStocks([...S_Stocks.stocks]);
      setIsDialogEditStockOpened(false);
      setStockToEdit(undefined);
    });
  }
  function closeEditStock(){
    setIsDialogEditStockOpened(false);
    setStockToEdit(undefined);
  }

  // ----------------------------

  function addDrink(){
    setIsDialogAddDrinkOpened(true);
  }
  function addDrinkValidate(dateInMillis,ml,min,mode){
    S_Drinks.add(dateInMillis,ml,min,mode,() => {
      setDrinks([...S_Drinks.drinks]);
      setIsDialogAddDrinkOpened(false);
    });
  }
  function editDrink(drink:I_Drink){
    setDrinkToEdit(drink);
    setIsDialogEditDrinkOpened(true);
  }
  function editDrinkValidate(dateInMillis,ml,min,mode){
    S_Drinks.update(dateInMillis,'date',drinkToEdit,() => {
      S_Drinks.update(ml,'ml',drinkToEdit,() => {
        S_Drinks.update(min,'min',drinkToEdit,() => {
          S_Drinks.update(mode,'mode',drinkToEdit,() => {
            setDrinks([...S_Drinks.drinks]);
            setIsDialogEditDrinkOpened(false);
            setDrinkToEdit(undefined);
          });
        });
      });
    });
  }
  function editDrinkDelete(){
    S_Drinks.remove(drinkToEdit,() => {
      setDrinks([...S_Drinks.drinks]);
      setIsDialogEditDrinkOpened(false);
      setDrinkToEdit(undefined);
    });
  }
  function closeEditDrink(){
    setIsDialogEditDrinkOpened(false);
    setDrinkToEdit(undefined);
  }

  // ----------------------------

  let panelContents = [
    <PanelHome
      pumps={pumps}
      addPump={addPump}
      editPump={editPump}
      stocks={stocks}
      addStock={addStock}
      editStock={editStock}
      drinks={drinks}
      addDrink={addDrink}
      editDrink={editDrink}
      selectType={selectType} />,
    <PanelPumps
      pumps={pumps}
      addPump={addPump}
      editPump={editPump} />,
    <PanelDrinks
      drinks={drinks}
      addDrink={addDrink}
      editDrink={editDrink} />,
    <PanelStocks
      stocks={stocks}
      addStock={addStock}
      editStock={editStock} />,
    <PanelConfig />
  ];

  let tabs = [
    {
      label: 'Accueil',
      icon: <BarChartIcon /> 
    },
    {
      label: 'Pompage',
      icon: <AddIcon /> 
    },
    {
      label: 'Consommation',
      icon: <RemoveIcon /> 
    },
    {
      label: 'Stocks',
      icon: <StorageIcon /> 
    },
    {
      label: 'Config',
      icon: <MoreHorizOutlinedIcon /> 
    }
  ]; 

  // https://getwaves.io/
  let PanelWaves = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#0099ff" fill-opacity="1" d="M0,256L40,256C80,256,160,256,240,224C320,192,400,128,480,128C560,128,640,192,720,229.3C800,267,880,277,960,256C1040,235,1120,181,1200,149.3C1280,117,1360,107,1400,101.3L1440,96L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>
    </svg>
  );


  return (
    <>
    <Panels 
      enabled={swipePanelAllowed}
      contents={panelContents}
      tabs={tabs}
      defaultPanelIndex={0}
      tabsLabelOnSmallScreen={true}
      topContent={PanelWaves}
      size="small"/>
    <DialogSelectType
      isDialogOpened={isDialogSelectTypeOpened}
      closeDialog={(e) => setIsDialogSelectTypeOpened(false)}
      onClickValidate={selectTypeValidate} />
    <DialogAddPump
      isDialogOpened={isDialogAddPumpOpened}
      closeDialog={(e) => setIsDialogAddPumpOpened(false)}
      onClickValidate={addPumpValidate} />
    {pumpToEdit ? (
    <DialogEditPump
      isDialogOpened={isDialogEditPumpOpened}
      closeDialog={closeEditPump}
      onClickDelete={editPumpDelete}
      onClickValidate={editPumpValidate}
      item={pumpToEdit} />
    ):null}
    <DialogAddDrink
      isDialogOpened={isDialogAddDrinkOpened}
      closeDialog={(e) => setIsDialogAddDrinkOpened(false)}
      onClickValidate={addDrinkValidate} />
    {drinkToEdit ? (
    <DialogEditDrink
      isDialogOpened={isDialogEditDrinkOpened}
      closeDialog={closeEditDrink}
      onClickDelete={editDrinkDelete}
      onClickValidate={editDrinkValidate}
      item={drinkToEdit} />
    ):null}
    <DialogAddStock
      isDialogOpened={isDialogAddStockOpened}
      closeDialog={(e) => setIsDialogAddStockOpened(false)}
      onClickValidate={addStockValidate} />
    {stockToEdit ? (
    <DialogEditStock
      isDialogOpened={isDialogEditStockOpened}
      closeDialog={closeEditStock}
      onClickDelete={editStockDelete}
      onClickValidate={editStockValidate}
      item={stockToEdit} />
    ):null}
    </>
  );
}

export default App;
