import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';


import ToggleButton      from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import S_Stocks         from '../services/S_Stocks';
import {I_Stock}        from '../services/I_Stock';

// Styles
// import './DialogAddStock.scss';

type DialogAddStockProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickValidate: any;
}

function DialogAddStock(props:DialogAddStockProps) {

  // props
  let {isDialogOpened,closeDialog,onClickValidate} = props;

  let currentDayMillis = S_Date.getCurrentDayReference();

  // state
  const [datePumpForMaterial, setDatePumpForMaterial] = useState(S_Date.formatDateForMaterial(currentDayMillis));
  const [dateForMaterial, setDateForMaterial] = useState(S_Date.formatDateForMaterial(currentDayMillis));
  const [ml, setMl] = useState(40);
  const [where, setWhere] = useState('Frigo');
  const [category1, setCategory1] = useState(undefined);

  function changeDatePump(value){
    setDatePumpForMaterial(value);
  }
  function changeDate(value){
    setDateForMaterial(value);
  }
  function changeMl(value){
    setMl(value);
  }
  function changeWhere(value){
    setWhere(value);
  }
  function changeCategory1(value){
    if(value === '') value = undefined;
    setCategory1(value);
  }

  function close(){
    closeDialog();
    setDatePumpForMaterial(S_Date.formatDateForMaterial(currentDayMillis));
    setDateForMaterial(S_Date.formatDateForMaterial(currentDayMillis));
    setMl(40);
    setWhere('Frigo');
    setCategory1(undefined);
  }
  function validate(){
    onClickValidate(daySelectedInMillis_pump,daySelectedInMillis,ml,where,category1);
    setDatePumpForMaterial(S_Date.formatDateForMaterial(currentDayMillis));
    setDateForMaterial(S_Date.formatDateForMaterial(currentDayMillis));
    setMl(40);
    setWhere('Frigo');
    setCategory1(undefined);
  }

  // on transforme la date "material" en date in millis, et on la cale à 1h.
  let daySelectedInMillis_pump = S_Date.getDayReference(S_Date.formatDateInMillis(datePumpForMaterial));
  let daySelectedInMillis = S_Date.getDayReference(S_Date.formatDateInMillis(dateForMaterial));

  // suggestions de category1
  let categories1Map = S_App.groupByCategory1(S_Stocks.stocks);
  let categories1 = !!categories1Map ? Object.keys(categories1Map) : [];

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Ajouter un élément de stock</DialogTitle>
      <DialogContent>


        <TextField id="quantity" label={'Quantité stockée (ml)'} type="number" InputProps={{inputProps: { min: 0 }}} fullWidth size="small" autoFocus={true}
          value={ml}
          onChange={(e) => changeMl(e.target.value)} />

        <TextField label={'Date de pompage'} type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth
          value={S_Date.formatDateForMaterial(datePumpForMaterial)} 
          onChange={(e) => changeDatePump(e.target.value)} />
        <TextField label={where == 'Congel' ? 'Date de congélation' : 'Date de stockage'} type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth
          value={S_Date.formatDateForMaterial(dateForMaterial)} 
          onChange={(e) => changeDate(e.target.value)} />
        <div className="text-left">
          <div>Lieu de stockage</div>
          <ToggleButtonGroup value={where} exclusive onChange={(event, newAlignment) => changeWhere(newAlignment)} aria-label="Lieu de stockage">
            <ToggleButton value="Tempéré" aria-label="Tempéré">
              Temp.
            </ToggleButton>
            <ToggleButton value="Frigo" aria-label="Frigo">
              Frig.
            </ToggleButton>
            <ToggleButton value="Congel" aria-label="Congel">
              Cong.
            </ToggleButton>
            <ToggleButton value="Décongelé" aria-label="Décongelé">
              Décong.
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        <TextField id="category1" label={'Catégorie'} type="text" fullWidth size="small" InputLabelProps={{ shrink: true }}
          value={category1}
          onChange={(e) => changeCategory1(e.target.value)} />
        {categories1.length>0 ? (
          <div className="suggestions">
          {categories1.map((category1,index) => (
            <div className="suggestion" onClick={(e) => {changeCategory1(category1)}}>{category1}</div>
          ))}
          </div>
        ):null}

      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">Annuler</Button>
        <Button onClick={validate} color="primary">Valider</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogAddStock;
