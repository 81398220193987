// Composant version 2021
// ----------------------------------

import React,{useEffect,useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

// Composants génériques
import S_App      from '../../services/S_App';
// import Version    from '../../elements/Version';
// import Credits    from '../../elements/Credits';
// import Saves      from '../../components/Saves';
import { Saves } from 'react-mui-pwa-tools';
import { Version } from 'react-mui-pwa-tools';
import { Credits } from 'react-mui-pwa-tools';

// Composants propres
import S_Pumps    from '../services/S_Pumps';
import S_Drinks   from '../services/S_Drinks';
import S_Stocks   from '../services/S_Stocks';

// Styles
// import './PanelConfig.scss';

import pkg from '../../../package.json';

type PanelConfigProps = {

}

function PanelConfig(props:PanelConfigProps) {

  // props
  let {} = props;

  // state

  // chargement des donnees

  // autres variables
  const isDEV = !!process.env && process.env.NODE_ENV === 'development';

  return (
    <Container fixed className="panel-contents">
      
      <Saves 
        storageKeyPrefix={S_App.STORAGE_KEY_PREFIX}
        storageKeys={[S_Pumps.STORAGE_KEY_PUMPS,S_Drinks.STORAGE_KEY_DRINKS,S_Stocks.STORAGE_KEY_STOCKS]}
        shareTitle="Dashboard lait maternel"
        className="mt-vague" />
      <Version 
        development={isDEV}
        version={pkg.version}
        lastUpdate={pkg.lastUpdate}/>
      <Credits>
        Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
      </Credits>

    </Container>
  );
}

export default PanelConfig;
