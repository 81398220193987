import S_App    from   '../../services/S_App';
import S_Date   from   '../../services/S_Date';
import {I_Stock} from   './I_Stock';

let TEST_STOCKS = [];

let S_Stocks = {
  STORAGE_KEY_STOCKS : 'stocks',
  isLogDebug: process.env.REACT_APP_ENV !== 'production',

  stocks : [],

  fetch: (cb) => {
    if(S_Stocks.isLogDebug) console.log('-- S_Stocks.tsx -- fetch()...');
    var stocks = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Stocks.STORAGE_KEY_STOCKS];
    if(stocks){
      S_Stocks.stocks = JSON.parse(stocks);
    }else{
      S_Stocks.stocks = TEST_STOCKS;
    }
      
    if(S_Stocks.isLogDebug) console.log('-- S_Stocks.tsx -- fetch() stocks',S_Stocks.stocks);
    if(cb)
      cb();
  },
  store: (cb) => {
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Stocks.STORAGE_KEY_STOCKS] = JSON.stringify(S_Stocks.stocks);
    if(S_Stocks.isLogDebug) console.log('-- S_Stocks.tsx -- store() stocks',S_Stocks.stocks); 
    if(cb)
      cb();
  },


  getById: (id:number|string) => {
    return S_App.getByID(S_Stocks.stocks,id);
  },

  add(dateInMillis_pump,dateInMillis,ml,where,category1,cb){
    if(S_App.isLogDebug) console.log('-- S_Stocks.tsx -- add()...');
      
    let dateNow = (new Date()).getTime();
    let newOne = {
      id: dateNow,
      datePump: dateInMillis_pump,
      date: dateInMillis,
      ml: parseInt(ml+''),
      where: where,
      used: undefined,
      dateUsed: undefined,
      category1: category1
    };
    if(!S_Stocks.stocks)
      S_Stocks.stocks = [];
    S_Stocks.stocks.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Stocks.tsx -- add() added',newOne);
    S_Stocks.store(() => {
      if(cb) cb();
    });
  },
  update(value,field,stock:I_Stock,cb){
    if(!S_Stocks.stocks || S_Stocks.stocks.length===0 || !stock){
      if(cb) cb();
      return;
    }
      
    let foundData = undefined;
    for(var i = 0; i < S_Stocks.stocks.length; i++){
      if(S_Stocks.stocks[i].id === stock.id){
        foundData = S_Stocks.stocks[i];
        if('ml'===field){
          foundData[field] = parseInt(value+'');
        }else if('datePump'===field || 'date'===field || 'dateUsed'===field){
          // value doit etre en millis
          foundData[field] = value!==undefined ? (new Date(value)).getTime() : undefined;
        }else{
          foundData[field] = value;
        }
        break;
      }
    }
    // console.log('Update',field,'as',value,'...',foundData);
    S_Stocks.store(() => {
      if(cb) cb(foundData);
    });
  },
  remove(stock:I_Stock,cb){
    if(!S_Stocks.stocks || S_Stocks.stocks.length===0 || !stock){
      if(cb) cb();
      return;
    }
    if(S_App.isLogDebug) console.log('-- S_Stocks.tsx -- remove() delete',stock,'...'); 
    let foundNoteIndex = S_App.getIndexOfByID(S_Stocks.stocks,stock.id);
    if(foundNoteIndex !== -1){
      S_Stocks.stocks.splice(foundNoteIndex,1);
      if(S_App.isLogDebug) console.log('-- S_Stocks.tsx -- remove() deleted'); 
    }else{
      if(S_App.isLogDebug) console.warn('-- S_Stocks.tsx -- remove() not found');
    }
    S_Stocks.store(() => {
      if(cb) cb();
    });
  },

  // tous les stocks consommes avant ou pendant tel jour
  getMlUsedBefore(dateInMillis):number{
    let usedMl = 0;
    for(var i = 0; i < S_Stocks.stocks.length; i++){
      let foundData = S_Stocks.stocks[i];
      let ml = foundData.ml;
      let dateUsed = foundData.dateUsed!==undefined && foundData.dateUsed!==null ? S_Date.getDayReference(foundData.dateUsed) : undefined;
      // if(S_App.isLogDebug) console.log('-- S_Stocks.tsx -- getMlUsedBefore()',foundData,dateInMillis,dateUsed <= S_App.getDayReference(dateInMillis)); 
      if(dateUsed!==undefined && dateUsed <= S_Date.getDayReference(dateInMillis))
        usedMl += ml;
    }
    return usedMl;
  },
  // stocks consommes tel jour
  getMlUsedAtDay(dateInMillis):number{
    let usedMl = 0;
    for(var i = 0; i < S_Stocks.stocks.length; i++){
      let foundData = S_Stocks.stocks[i];
      let ml = foundData.ml;
      let dateUsed = foundData.dateUsed!==undefined && foundData.dateUsed!==null ? S_Date.getDayReference(foundData.dateUsed) : undefined;
      // if(S_App.isLogDebug) console.log('-- S_Stocks.tsx -- getMlUsedBefore()',foundData,dateInMillis,dateUsed <= S_App.getDayReference(dateInMillis)); 
      if(dateUsed!==undefined && dateUsed === S_Date.getDayReference(dateInMillis))
        usedMl += ml;
    }
    return usedMl;
  },
  // stocks avec category1 undefined
  getStocksWithNoCategory1(array):I_Stock[]{
    if(!array)
      return [];
    let arrayUndefined = [];
    for(var i = 0; i < array.length; i++){
      if(!array[i].category1 || array[i].category1==='')
        arrayUndefined.push(array[i]);
    }
    return arrayUndefined;
  }

};
export default S_Stocks;