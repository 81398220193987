// Composant version 2021
// ----------------------------------

import React,{useEffect,useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

// Composants génériques
import S_App      from '../../services/S_App';

// Composants propres
import {I_Stock}         from '../services/I_Stock';
import StocksAccordions    from './StocksAccordions';

// Styles
// import './PanelStocks.scss';

type PanelStocksProps = {
  stocks: I_Stock[];
  addStock: any;
  editStock: any;
}

function PanelStocks(props:PanelStocksProps) {

  // props
  let {stocks,addStock,editStock} = props;

  // state


  // chargement des donnees
  useEffect(() => {

  }, []);

  // autres variables

  return (
    <Container fixed className="panel-contents pb-6">

      <div className="mt-vague">
        <StocksAccordions stocks={stocks} editStock={editStock}/>
      </div>
      
      <div className="panel-contents-footer text-right">
        <Fab aria-label="Ajouter" onClick={addStock} className=""><AddIcon /></Fab>
      </div>
    </Container>
  );
}

export default PanelStocks;
