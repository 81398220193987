// Composant version 2021
// ----------------------------------

import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import {I_Drink}         from '../services/I_Drink';

// Styles
// import './Accordions.scss';

type DrinksAccordionsProps = {
  Drinks: I_Drink[];
  editDrink: any;
}

function DrinksAccordions(props:DrinksAccordionsProps) {

  // props
  let {Drinks,editDrink} = props;

  // state



  // autres variables

  let currentDayMillis = S_Date.getCurrentDayReference();

  if(!Drinks)
    return null;

  let DrinksByDate = S_Date.groupByDate(Drinks);
  if(!DrinksByDate)
    return null;

  return (
    <div>
      {Object.keys(DrinksByDate).map((date,index) => {
        let dayItems = DrinksByDate[date];
        // console.log('currentDayMillis',currentDayMillis,'day.date',dayDate,dayDate===currentDayMillis);
        let totalDay = 0;
        for(var i = 0; i < dayItems.length; i++){
          totalDay += dayItems[i].ml;
        }
        return (
          <Accordion key={index} defaultExpanded={date+''===currentDayMillis+''} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className="capitalize">
              <strong>{S_Date.formatDateInStringWithName(parseInt(date))}</strong>
              <i className="mh-05">{totalDay}ml</i>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {dayItems.map((item,index2) => (
                  <div key={index2} onClick={(e) => editDrink(item)} className="mv-05">
                    {item.ml}ml
                    {!!item.min ? <i className="mh-05">en {item.min}min</i> : null}
                    {!!item.mode ? <i className="mh-05"><strong>{item.mode}</strong></i> : null}
                    {!!item.min && !!item.ml ? <i className="mh-05">{Math.round(item.ml / item.min)}ml/min</i> : null}
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

export default DrinksAccordions;
