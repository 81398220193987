// Composant version 2021
// ----------------------------------

import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import S_Stocks         from '../services/S_Stocks';
import {I_Stock}        from '../services/I_Stock';

// Styles
// import './Accordions.scss';

type StocksAccordionsProps = {
  stocks: I_Stock[];
  editStock: any;
}

function StocksAccordions(props:StocksAccordionsProps) {

  // props
  let {stocks,editStock} = props;

  // state



  // autres variables

  let currentDayMillis = S_Date.getCurrentDayReference();

  if(!stocks)
    return <div>Aucun stock</div>;

  let stocksByWhere = S_App.groupByWhere(stocks);
  if(!stocksByWhere)
    return null;

  // on affiche d'abord ceux imposes, puis les autres
  let wheresConstants = ['Tempéré','Frigo','Congel','Décongelé'];


  return (
    <div>
      {wheresConstants.map((where,index) => {
        let whereItems = stocksByWhere[where];

        let totalWhere = 0;
        let totalWhereNotUsed = 0;
        let totalWhereNotUsedWithNoCategory1 = 0;
        if(whereItems===undefined)
          return null;
        for(var i = 0; i < whereItems.length; i++){
          totalWhere += whereItems[i].ml;
          if(whereItems[i].used===undefined || whereItems[i].used.trim()==='')
            totalWhereNotUsed += whereItems[i].ml;
          if((whereItems[i].used===undefined || whereItems[i].used.trim()==='') && (whereItems[i].category1===undefined || whereItems[i].category1.trim()===''))
            totalWhereNotUsedWithNoCategory1 += whereItems[i].ml;
        }

        let whereItemsByCategory1 = S_App.groupByCategory1(whereItems);
        let categories1 = Object.keys(whereItemsByCategory1);

        let stocksWithNoCategory1 = S_Stocks.getStocksWithNoCategory1(whereItems);


        return (
          <>
          <div>
            <h3 color="primary">Total {where} : 
            {totalWhereNotUsed !== totalWhere ? (
            <i className="mh-05">{totalWhereNotUsed>=1000 ? totalWhereNotUsed/1000 + 'L' : totalWhereNotUsed + 'mL'} sur {totalWhere>=1000 ? totalWhere/1000 + 'L' : totalWhere + 'mL'}</i>
            ) : (
            <i className="mh-05">{totalWhere>=1000 ? totalWhere/1000 + 'L' : totalWhere + 'mL'}</i>
            )}
            </h3>
          </div>

          <Accordion key={index} defaultExpanded={false} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <strong>{where}</strong>
              <i className="mh-05">{totalWhereNotUsedWithNoCategory1>=1000 ? totalWhereNotUsedWithNoCategory1/1000 + 'L' : totalWhereNotUsedWithNoCategory1 + 'mL'}</i>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {stocksWithNoCategory1.map((item,index2) => (
                  <div key={index2} onClick={(e) => editStock(item)} className="mv-05">
                    {!!item.used ? (
                      <span className="stockUsed">
                        <span className="d-ib ml">{item.ml>=1000 ? item.ml/1000 + 'L' : item.ml + 'mL'}</span>
                        <span className="mh-05 d-ib lh-1 date">Pompé le <br/>{S_Date.formatDateInStringWithName(item.datePump)}</span>
                        <span className="mh-05 d-ib lh-1 date">Stocké le <br/>{S_Date.formatDateInStringWithName(item.date)}</span>
                        <span className="mh-05 d-ib lh-1 date">{item.used} le <br/>{item.dateUsed ? <span>{S_Date.formatDateInStringWithName(item.dateUsed)}</span> : null}</span>
                        <span className="mh-05 d-ib lh-1 date">{item.category1}</span>
                      </span>
                    ) : (
                      <span className="stockNotUsed">
                        <span className="d-ib ml">{item.ml>=1000 ? item.ml/1000 + 'L' : item.ml + 'mL'}</span>
                        <span className="mh-05 d-ib lh-1 date">Pompé le <br/>{S_Date.formatDateInStringWithName(item.datePump)}</span>
                        <span className="mh-05 d-ib lh-1 date">Stocké le <br/>{S_Date.formatDateInStringWithName(item.date)}</span>
                        <span className="mh-05 d-ib lh-1 date">{item.category1}</span>
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>

          {categories1.map((category1,index2) => {
            let items = whereItemsByCategory1[category1];

            let totalWhereNotUsedWithCategory1 = 0;
            for(var i = 0; i < items.length; i++){
              if(items[i].used===undefined || items[i].used.trim()==='')
                totalWhereNotUsedWithCategory1 += items[i].ml;
            }

            return (
              <Accordion key={index2} defaultExpanded={false} TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <strong>{where} - {category1}</strong>
                <i className="mh-05">{totalWhereNotUsedWithCategory1>=1000 ? totalWhereNotUsedWithCategory1/1000 + 'L' : totalWhereNotUsedWithCategory1 + 'mL'}</i>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    {whereItemsByCategory1[category1].map((item,index3) => (
                      <div key={index3} onClick={(e) => editStock(item)} className="mv-05">
                        {!!item.used ? (
                          <span className="stockUsed">
                            <span className="d-ib ml">{item.ml>=1000 ? item.ml/1000 + 'L' : item.ml + 'mL'}</span>
                            <span className="mh-05 d-ib lh-1 date">Pompé le <br/>{S_Date.formatDateInStringWithName(parseInt(item.datePump))}</span>
                            <span className="mh-05 d-ib lh-1 date">Stocké le <br/>{S_Date.formatDateInStringWithName(parseInt(item.date))}</span>
                            <span className="mh-05 d-ib lh-1 date">{item.used} le <br/>{item.dateUsed ? <span>{S_Date.formatDateInStringWithName(parseInt(item.dateUsed))}</span> : null}</span>
                          </span>
                        ) : (
                          <span className="stockNotUsed">
                            <span className="d-ib ml">{item.ml>=1000 ? item.ml/1000 + 'L' : item.ml + 'mL'}</span>
                            <span className="mh-05 d-ib lh-1 date">Pompé le <br/>{S_Date.formatDateInStringWithName(parseInt(item.datePump))}</span>
                            <span className="mh-05 d-ib lh-1 date">Stocké le <br/>{S_Date.formatDateInStringWithName(parseInt(item.date))}</span>
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}

          </>
        );
      })}
    </div>
  );
}

export default StocksAccordions;
