import S_App    from   '../../services/S_App';
import {I_Pump} from   './I_Pump';

let TEST_PUMPS = [];

let S_Pumps = {
  STORAGE_KEY_PUMPS : 'pumps',

  pumps : [],

  fetch: (cb) => {
    if(S_App.isLogDebug) console.log('-- S_Pumps.tsx -- fetch()...');
    var pumps = window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Pumps.STORAGE_KEY_PUMPS];
    if(pumps){
      S_Pumps.pumps = JSON.parse(pumps);
    }else{
      S_Pumps.pumps = TEST_PUMPS;
    }

    for(var i = 0; i < S_Pumps.pumps.length; i++){
      S_Pumps.pumps[i].ml = parseInt(S_Pumps.pumps[i].ml+'');
    }
    S_Pumps.store(() => {});
      
    if(S_App.isLogDebug) console.log('-- S_Pumps.tsx -- fetch() pumps',S_Pumps.pumps);
    if(cb)
      cb();
  },
  store: (cb) => {
    window.localStorage[S_App.STORAGE_KEY_PREFIX + '_' + S_Pumps.STORAGE_KEY_PUMPS] = JSON.stringify(S_Pumps.pumps);
    if(S_App.isLogDebug) console.log('-- S_Pumps.tsx -- store() pumps',S_Pumps.pumps); 
    if(cb)
      cb();
  },


  getById: (id:number|string) => {
    return S_App.getByID(S_Pumps.pumps,id);
  },

  add(dateInMillis,ml,min,mode,cb){
    if(S_App.isLogDebug) console.log('-- S_Pumps.tsx -- add()...');
      
    let dateNow = (new Date()).getTime();
    let newOne = {
      id: dateNow,
      date: dateInMillis,
      ml: parseInt(ml+''),
      min: min,
      mode: mode
    };
    if(!S_Pumps.pumps)
      S_Pumps.pumps = [];
    S_Pumps.pumps.push(newOne);
    if(S_App.isLogDebug) console.log('-- S_Pumps.tsx -- add() added',newOne);
    S_Pumps.store(() => {
      if(cb) cb();
    });
  },
  update(value,field,pump:I_Pump,cb){
    if(!S_Pumps.pumps || S_Pumps.pumps.length===0 || !pump){
      if(cb) cb();
      return;
    }
      
    let foundData = undefined;
    for(var i = 0; i < S_Pumps.pumps.length; i++){
      if(S_Pumps.pumps[i].id === pump.id){
        foundData = S_Pumps.pumps[i];
        if('ml'===field || 'min'===field){
          foundData[field] = parseInt(value+'');
        }else if('date'===field){
          // value doit etre en millis
          foundData[field] = value!==undefined ? (new Date(value)).getTime() : undefined;
        }else{
          foundData[field] = value;
        }
        break;
      }
    }
    console.log('Update',field,'as',value,'...',foundData);
    S_Pumps.store(() => {
      if(cb) cb(foundData);
    });
  },
  remove(pump:I_Pump,cb){
    if(!S_Pumps.pumps || S_Pumps.pumps.length===0 || !pump){
      if(cb) cb();
      return;
    }
    if(S_App.isLogDebug) console.log('-- S_Pumps.tsx -- remove() delete',pump,'...'); 
    let foundNoteIndex = S_App.getIndexOfByID(S_Pumps.pumps,pump.id);
    if(foundNoteIndex !== -1){
      S_Pumps.pumps.splice(foundNoteIndex,1);
      if(S_App.isLogDebug) console.log('-- S_Pumps.tsx -- remove() deleted'); 
    }else{
      if(S_App.isLogDebug) console.warn('-- S_Pumps.tsx -- remove() not found');
    }
    S_Pumps.store(() => {
      if(cb) cb();
    });
  }

};
export default S_Pumps;