// Composant version 2021
// ----------------------------------

import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import { ResponsiveContainer, BarChart, PieChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Pie, Cell } from 'recharts'; // https://recharts.org/en-US/api/ResponsiveContainer

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import {I_Stock}         from '../services/I_Stock';

// Styles
// import './WherePie.scss';

type WherePieProps = {
  stocks: I_Stock[];
}

function WherePie(props:WherePieProps) {

  // props
  let {stocks} = props;

  // state



  // autres variables
  let data2 = [];
  let wheresConstants = ['Tempéré','Frigo','Congel','Décongelé'];
  let usedConstants = ['Consommé','Jeté'];
  let totalWheresUsed1 = 0;  // total consomme
  let totalWheresUsed2 = 0;  // total jete
  let totalUsed = 0;         // total used autres

  // totaux par lieux
  let stocksByWhere = S_App.groupByWhere(stocks);
  for(var i = 0; i < wheresConstants.length; i++){
    let where = wheresConstants[i];
    let stocksOfWhere = stocksByWhere[where];
    let totalWhere = 0;
    let totalWhereNotUsed = 0;
    if(!!stocksOfWhere){
      for(var j = 0; j < stocksOfWhere.length; j++){
        let ml = stocksOfWhere[j].ml;
        totalWhere += ml;
        if(stocksOfWhere[j].used===undefined || stocksOfWhere[j].used.trim()===''){
          totalWhereNotUsed += stocksOfWhere[j].ml;
        }
      }
    }
    if(totalWhere>0)
      data2.push({
        "where": where,
        "ml": totalWhereNotUsed
      });
  }
  // totaux autres lieux (obsolete)
  let wheres = Object.keys(stocksByWhere);
  wheres = wheres.sort();
  // for(var i = 0; i < wheres.length; i++){
  //   let where = wheres[i];
  //   if(wheresConstants.indexOf(where)!==-1)  // le total a deja ete pris en compte
  //     continue;
  //   let stocksOfWhere = stocksByWhere[where];
  //   let totalWhere = 0;
  //   let totalWhereNotUsed = 0;
  //   if(!!stocksOfWhere){
  //     for(var j = 0; j < stocksOfWhere.length; j++){
  //       let ml = stocksOfWhere[j].ml;
  //       totalWhere += ml;
  //       if(stocksOfWhere[j].used===undefined || stocksOfWhere[j].used.trim()===''){
  //         totalWhereNotUsed += stocksOfWhere[j].ml;
  //       }
  //     }
  //   }
  //   if(totalWhere>0)
  //     data2.push({
  //       "where": '? '+where,
  //       "ml": totalWhereNotUsed
  //     });
  // }
  // totaux used
  for(var i = 0; i < wheres.length; i++){
    let where = wheres[i];
    let stocksOfWhere = stocksByWhere[where];
    if(!!stocksOfWhere){
      for(var j = 0; j < stocksOfWhere.length; j++){
        if(stocksOfWhere[j].used===undefined || stocksOfWhere[j].used.trim()==='')
          continue; 
        if(stocksOfWhere[j].used==='Consommé'){
          totalWheresUsed1 += stocksOfWhere[j].ml;
        }else if(stocksOfWhere[j].used==='Jeté'){
          totalWheresUsed2 += stocksOfWhere[j].ml;
        }else{
          totalUsed += stocksOfWhere[j].ml;
        }
      }
    }
  }
  if(totalWheresUsed1>0)
    data2.push({
      "where": 'Consommé',
      "ml": totalWheresUsed1
    });
  if(totalWheresUsed2>0)
    data2.push({
      "where": 'Jeté',
      "ml": totalWheresUsed2
    });
  if(totalUsed>0)
    data2.push({
      "where": 'Autre',
      "ml": totalUsed
    });
  // console.log(data2);
  let currentDayMillis = S_Date.getCurrentDayReference();

  return (
    <ResponsiveContainer height={250}>
      <PieChart>
        <Pie data={data2} dataKey="ml" nameKey="where" cx="50%" cy="50%" label={(entry) => entry.where + ' ' + (entry.ml>=1000 ? entry.ml/1000 + 'L' : entry.ml + 'mL')} fill="#8884d8" startAngle={270} endAngle={-90} outerRadius={60}>
          {data2.map((entry, index) => {
            // let color = COLORS[index % COLORS.length];
            let color = '#000000';
            if(entry.where === 'Tempéré') color = '#8c5bff';
            if(entry.where === 'Frigo') color = '#5ba5ff';
            if(entry.where === 'Congel') color = '#5bf1ff';
            if(entry.where === 'Décongelé') color = '#5bf1ff';

            if(entry.where === 'Consommé') color = '#6d6d6d';
            if(entry.where === 'Jeté') color = '#6d6d6d';
            if(entry.where === 'Autre') color = '#6d6d6d';
            return (
              <Cell key={'cell-${index}'} fill={color} />
            );
          })}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default WherePie;
