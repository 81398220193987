// Composant version 2021
// ----------------------------------

import React,{useEffect,useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

// Composants génériques
import S_App      from '../../services/S_App';

// Composants propres
import {I_Pump}         from '../services/I_Pump';
import PumpsAccordions    from './PumpsAccordions';

// Styles
// import './PanelPumps.scss';

type PanelPumpsProps = {
  pumps: I_Pump[];
  addPump: any;
  editPump: any;
}

function PanelPumps(props:PanelPumpsProps) {

  // props
  let {pumps,addPump,editPump} = props;

  // state


  // chargement des donnees
  useEffect(() => {

  }, []);

  // autres variables

  return (
    <Container fixed className="panel-contents pb-6">

      <div className="mt-vague">
        <PumpsAccordions pumps={pumps} editPump={editPump}/>
      </div>
      
      <div className="panel-contents-footer text-right">
        <Fab aria-label="Ajouter" onClick={addPump} className=""><AddIcon /></Fab>
      </div>
    </Container>
  );
}

export default PanelPumps;
