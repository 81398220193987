import React,{useState} from 'react';
// import PropTypes from 'prop-types';

// Composants Material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

// Composants génériques
import S_App      from '../../services/S_App';
import S_Date     from '../../services/S_Date';

// Composants propres
import S_Drinks         from '../services/S_Drinks';
import {I_Drink}        from '../services/I_Drink';

// Styles
// import './DialogAddDrink.scss';

type DialogAddDrinkProps = {
  isDialogOpened: boolean;
  closeDialog: any;            // function close

  onClickValidate: any;
}

function DialogAddDrink(props:DialogAddDrinkProps) {

  // props
  let {isDialogOpened,closeDialog,onClickValidate} = props;

  let currentDayMillis = S_Date.getCurrentDayReference();

  // state
  const [dateForMaterial, setDateForMaterial] = useState(S_Date.formatDateForMaterial(currentDayMillis));
  const [ml, setMl] = useState(40);
  const [min, setMin] = useState(undefined);
  const [mode, setMode] = useState(undefined);

  function changeDate(value){
    setDateForMaterial(value);
  }
  function changeMl(value){
    setMl(value);
  }
  function changeMin(value){
    setMin(value);
  }
  function changeMode(value){
    setMode(value);
  }

  // on transforme la date "material" en date in millis, et on la cale à 1h.
  let daySelectedInMillis = S_Date.getDayReference(S_Date.formatDateInMillis(dateForMaterial));

  return (
    <>
    <Dialog
      open={isDialogOpened}
      onClose={closeDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Ajouter un biberon</DialogTitle>
      <DialogContent>

        <TextField label="Date" type="date" size="small" InputLabelProps={{ shrink: true }} fullWidth 
          value={S_Date.formatDateForMaterial(dateForMaterial)} 
          onChange={(e) => changeDate(e.target.value)} />
        <TextField id="quantity" label={'Quantité bue (ml)'} type="number" InputProps={{inputProps: { min: 0 }}} fullWidth size="small" autoFocus={true}
          value={ml}
          onChange={(e) => changeMl(e.target.value)} />
        <TextField id="time" label={'En ?? minutes'} type="number" InputProps={{inputProps: { min: 0 }}} fullWidth size="small"
          value={min}
          onChange={(e) => changeMin(e.target.value)} />
        <TextField id="mode" label={'Mémo tétine (nb de trous, marque...)'} type="text" fullWidth size="small"
          value={mode}
          onChange={(e) => changeMode(e.target.value)} />

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">Annuler</Button>
        <Button onClick={(e) => onClickValidate(daySelectedInMillis,ml,min,mode)} color="primary">Valider</Button>
      </DialogActions>
    </Dialog>
    </>
  );
}

export default DialogAddDrink;
